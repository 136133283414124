<section (click)="openCard()" [ngClass]="{ pointer: cardStatus === 'close' }" class="fut-card-small">
  <header (click)="toggleState($event)" [ngClass]="{ open: cardStatus === 'open' }" class="d-flex justify-content-between align-items-center card-top-section">
    <h3 *futGhostStateFragment="state; textMinChar: 4; textMaxChar: 15" class="mb-0">
      @if (cardTitle) {
        {{ cardTitle | transloco }}
      }
    </h3>
    <div class="d-flex align-items-center">
      @if (leftContent) {
        <ng-container *ngTemplateOutlet="leftContent"></ng-container>
      }
      <mat-icon fontSet="material-icons-outlined">expand_more</mat-icon>
    </div>
  </header>
  @if (cardStatus === 'open') {
    <div @accordion class="accordion-body">
      <ng-content></ng-content>
    </div>
  }
</section>
