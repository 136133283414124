<app-base-dialog dialogTitle="dialogs.material.title">
  <small class="dialog-subtitle">{{ subjectLabel }}</small>
  <div class="d-flex justify-content-between total-info">
    <span *futGhostStateFragment="state; text: 'seenMaterial'">{{ 'archive.info.seenMaterial.title' | transloco }}</span>
    <div class="d-flex align-items-center total-container">
      <span *futGhostStateFragment="state; text: '00/00'">
        <b>{{ completedMaterial }}</b
        ><small>/{{ totalMaterial }}</small>
      </span>
      <app-material-bar [percentage]="totalPercentage" [state]="state"></app-material-bar>
    </div>
  </div>
  <div class="material-dialog-body">
    @if (videos) {
      <app-collapsable-card [state]="state" cardTitle="dialogs.view_category_material.videolessons" [leftContent]="videoInfo" cardStatus="open">
        <ng-template #videoInfo>
          <div class="d-flex align-items-center card-info">
            @if (totalMaterial && completedMaterial === totalMaterial) {
              <mat-icon fontSet="material-icons">verified</mat-icon>
            }
            <span *futGhostStateFragment="state; text: '00/00'">
              <b>{{ completedMaterial }}</b
              ><small>/{{ totalMaterial }}</small>
            </span>
          </div>
        </ng-template>
        @for (video of videos; track video) {
          <div class="d-flex justify-content-between align-items-center material-info" (click)="materialAction({ material: video, type: 'video' })">
            <b *futGhostStateFragment="state; textMinChar: 3; textMaxChar: 10" class="material-title">{{ video.video_title }}</b>
            <div class="d-flex align-items-center action-info">
              @if (video.percentageView) {
                <app-material-bar [state]="state" [percentage]="video.percentageView" bgColor="white"></app-material-bar>
              }
              <ng-container *futGhostStateFragment="state; text: '0h 00m'">
                @if (!video.percentageView) {
                  <span class="text-opacity">
                    @if (video.durationH) {
                      {{ video.durationH | number: '1.0-0' }} h {{ video.durationM | number: '2.0-0' }}m
                    }
                    @if (!video.durationH) {
                      {{ video.durationM | number: '2.0-0' }} m {{ video.durationS | number: '2.0-0' }}s
                    }
                  </span>
                }
              </ng-container>
              @if (video.percentageView !== 100) {
                <fut-action-icon [state]="state" [buttonType]="!video.percentageView ? 'accent' : 'tertiary'" fontSet="material-icons-outlined"
                  >play_arrow
                </fut-action-icon>
              }
              @if (video.percentageView === 100) {
                <fut-action-icon [state]="state" fontSet="material-symbols-outlined" buttonType="secondary">done </fut-action-icon>
              }
            </div>
          </div>
        }
      </app-collapsable-card>
    }
  </div>
</app-base-dialog>
