import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProgressBarChartModel, ProgressBarIcon } from './progress-bar-chart.model';
import { MatIcon } from '@angular/material/icon';
import { DecimalPipe, NgClass } from '@angular/common';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';
import { TooltipDirective } from '@futura/futura-ui/tooltip';

@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.scss'],
  standalone: true,
  imports: [MatIcon, DecimalPipe, NgClass, TooltipDirective],
})
export class ProgressBarChartComponent implements OnChanges, ProgressBarChartModel {
  @Input() public state: ComponentState = COMPONENT_STATE.NONE;

  @Input() public total?: number;
  @Input() public value?: number;
  @Input() public percentage?: number;
  @Input() public forceIconPresence?: boolean;
  @Input() public hideIcon?: boolean;

  @Input() public color?: string | ((info: ProgressBarChartModel) => string);
  @Input() public bgColor: string | ((info: ProgressBarChartModel) => string) = 'var(--fut-secondary-200)';
  @Input() public icon?: string | ((info: ProgressBarChartModel) => string | ProgressBarIcon);

  @Input() public hideTooltip = false;

  @Input() public minPercentage = 0;

  public colorBar?: string;
  public colorBgBar = 'var(--fut-secondary-200)';
  public iconBar?: string;
  public fontSet = 'material-icons-outlined';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total || changes.value) {
      this.onChangePartials();
    }
    if (changes.percentage) {
      this.onChangeColor();
      this.onChangeIcon();
    }
    if (changes.color) {
      this.onChangeColor();
    }
    if (changes.icon) {
      this.onChangeIcon();
    }

    if (changes.bgColor) {
      this.onChangeBgColor();
    }
  }

  private onChangePartials(): void {
    if (!this.total || !this.value) {
      this.percentage = 0;
      return;
    }
    this.percentage = +((100 * this.value) / this.total).toFixed(2);
    this.onChangeColor();
    this.onChangeIcon();
  }

  private onChangeColor(): void {
    this.colorBar = typeof this.color === 'function' ? this.color(this) : this.color;
  }

  private onChangeBgColor(): void {
    this.colorBgBar = typeof this.bgColor === 'function' ? this.bgColor(this) : this.bgColor;
  }

  private onChangeIcon(): void {
    const result = typeof this.icon === 'function' ? this.icon(this) : this.icon;
    if (!result || typeof result === 'string') {
      this.iconBar = result;
      return;
    }
    const { icon, fontSet } = result;
    this.iconBar = icon;
    this.fontSet = fontSet;
  }
}
